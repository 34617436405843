<template>
  <!-- <Navbar/> -->
  <div class="add-agent-container">
    <!--<div class="background-image"></div> -->
    <h1>ADD AGENT TO DB</h1>
    <FormKit
      type="form"
      name="addAgentInfoForm"
      id="addAgentInfoForm"
      submit-label="Submit"
      @submit="submitHandler"
      :actions="false"
      :wrapper-class="{
        'formkit-wrapper': false,
      }"
    >
      <FormKit
        label="Name"
        placeholder="Agent's name"
        name="name"
        validation="required|text"
        v-model="formData.name"
        :wrapper-class="{
          'formkit-wrapper': false,
        }"
      />

      <FormKit
        label="Email"
        placeholder="Agent's email"
        name="email"
        type="email"
        validation="required|email"
        v-model="formData.email"
        :wrapper-class="{
          'formkit-wrapper': false,
        }"
      />

      <FormKit
        type="select"
        name="selectedTitle"
        :options="[
          'Academic Counselor',
          'ReEntry Rep',
          'Student Services',
          'Finance Manager',
          'Tribal Ops',
          'Program Specialist',
        ]"
        label="Title"
        placeholder="Title"
        v-model="formData.selectedTitle"
        :wrapper-class="{
          'formkit-wrapper': false,
        }"
      />

      <FormKit
        name="phoneNumber"
        label="Phone Number"
        placeholder="ex: +19876543210"
        validation="number|length:10,12|starts_with:+1"
        validation-visibility="live"
        type="text"
        help="Info: Please enter +1 followed by 10 digit phone number "
        v-model="formData.phoneNumber"
        :wrapper-class="{
          'formkit-wrapper': false,
        }"
      />

      <pre>
Seven digit number: {{
          formData.phoneNumber ? formData.phoneNumber.substring(5) : ""
        }}</pre
      >

      <FormKit
        id="submit-button"
        type="submit"
        label="Submit"
        :wrapper-class="{
          'formkit-wrapper': false,
        }"
        :input-class="{
          'formkit-input': true,
          btn: true,
        }"
      />
    </FormKit>

    <div>{{ responseMessage }}</div>

    <!--<FormKit type="search" placeholder="Search by Agent's Name or Title" label="Search" value="Hello" /> -->
  </div>
</template>

<script>
// import Navbar from './Navbar.vue';

import checkAuth from "../auth/checkAuth";
import { conditionalExpression } from "@babel/types";
import axios from "axios";
export default {
  name: "VoicemailPage",
  data() {
    return {
      responseMessage: "",
      provisionedUsers: [],
      formData: {
        name: "",
        email: "",
        selectedTitle: "",
        phoneNumber: "+1",
      },
    };
  },
  methods: {
    async submitHandler() {
      try {
        checkAuth();
        console.log("phone number - ", this.formData.phoneNumber);
        let sevenDigitNumber = "";
        if (!this.formData.phoneNumber.startsWith("+1")) {
          this.formData.phoneNumber = "+1" + this.formData.phoneNumber;
          sevenDigitNumber = this.formData.phoneNumber.substring(5, 12);
        } else {
          sevenDigitNumber = this.formData.phoneNumber.substring(5, 12);
        }

        /*
         * If selected Title is Student Services &
         *  if user do not enter Team email along with Agent's email
         *  address, include it by default
         */
  

        var email = "";
        if (
          this.formData.selectedTitle.includes("Student Services") &&
          !this.formData.email.toLowerCase().includes("studentservices")
        ) {
          email = this.formData.email.concat(",studentservicesvm@phoenix.edu");
        } else {
          email = this.formData.email;
        }

        console.log("Window Location Hostname ", window.location.hostname);
        const included = window.location.hostname.includes("cpap-int.uopx.io")
          ? true
          : false;
        console.log("Is Window Location Hostname Included? ", included);

        const selectedOption = this.formData.selectedTitle;
        console.log("slected title: ", selectedOption);
        const abbrTitles = {
          ac: "Academic Counselor",
          re: "ReEntry Rep",
          as: "Student Services",
          fm: "Finance Manager",
          to: "Tribal Ops",
          ps: "Program Specialist",
        };

        const abbreviatedTitle = Object.keys(abbrTitles).find((key) => {
          return abbrTitles[key] === selectedOption;
        });

        console.log("abbreviation: ", abbreviatedTitle);

        const axiosPostConfig = {
          method: "POST",
          url: window.location.hostname.includes("cpap-int.uopx.io")
            ? "https://api.uopx.io/api/communications/voice/v1/agent"
            : "https://api.st.uopx.io/api/communications/voice/v1/agent",
          data: {
            name: `${this.formData.name}`,
            email: email,
            title: abbreviatedTitle,
            number: `${this.formData.phoneNumber}`,
            sevenDigitNumber: `${sevenDigitNumber}`,
          },
          headers: {
            "X-Azure-Token": localStorage.getItem("jwt"),
            //"X-Azure-Token" : "Ka_V8AXNHe7fFgzX9RZ_bj6Wn4MJJPIqPoFQDQKahbf-cA56zTDnuyU3nzbJhiFX3xy-aamBTcF4laH124hOGP01gg_f8uz44Kkx0YZdzT0cswwvBjv1TapKT7wWxjmwqIusqE0iJyI-psIbK53_dzFGabaFk-f4mHmCuldapfNqatKFll0vqbesfbYSzuxL1Wy8sa2ekMHDMMCYUabuqnbNgvHkckw8SA3P0fKElnFaKvNQYwsFMob7Fl2-VthRyY114xzfkvYk5l6plTjr_KJKeBuxYsmyavGYh1jWAfsIkrBHikSdoUR-7cx9sFf1siBZO4NrW4g4ral84MZGVQ",
            "Content-Type": "application/json",
          },
        };
        console.log("Printing axiosPostConfig - ", axiosPostConfig);

        const axiosPostResponse = await axios(axiosPostConfig);
        console.log("axiosPostResponse: ", axiosPostResponse.data);

        this.responseMessage = `Voicemail added to Agent DB for ${this.formData.name}`;
        this.$formkit.reset("addAgentInfoForm");
      } catch (err) {
        console.error(err);
        this.responseMessage = err;
      }
    },
  },
};
</script>

<style scoped>
/* .background-image {
  height: 100vh;
  background: url(../assets/landingPage.jpg) center no-repeat;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  background-size: cover;
} */

h1 {
  margin-bottom: 20px;
  /* margin-top: 40px; */
}

.add-agent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

:deep(#addAgentInfoForm) {
  flex-direction: column;
  width: 400px;
}

:deep(.formkit-label) {
  text-align: left;
  font-size: 16px;
}

:deep(#submit-button) {
  width: 100%;
  margin-top: 20px;
}
</style>
